<template>
  <article class="article gate" v-lazy-container="{ selector: '.article__grid-stack-image, .groundplan', loading: getImagePath('loading-gate.gif') }">
    <section class="article__mood">
      <img src="../assets/images/gate/mood.jpg" class="article__mood-image">
      <div class="article__mood-text">
        <h3>{{ $t('gate.subtitle') }}</h3>
        <h1 class="article__mood-title">{{ $t('gate.title') }}</h1>
      </div>
    </section>
    <section class="article__text intro">
      <h3 data-aos="fade-up" class="intro__subtitle">{{ $t('gate.subtitle') }}</h3>
      <h2 data-aos="fade-up">{{ $t('gate.title') }}</h2>
    </section>
    <section class="article__mood">
      <img :data-src="getImagePath('groundplan.svg')" data-aos="fade-up" class="groundplan">
    </section>
    <section class="article__text article--bg-color-shade-lighter-offset">
      <h2 class="title--with-logo">
        <LogoInverted logoClass="title--logo" />

        <span data-aos="fade-up">{{ $t('gate.work_title') }}</span>
      </h2>
      <p data-aos="fade-up">{{ $t('gate.work_text') }}</p>
    </section>

    <Slider :view_list="this.json.gate.view_list" />

    <section class="article__text article--bg-color-highlight-second-offset">
      <h2 class="title--with-logo">
        <LogoInverted logoClass="title--logo" />

        <span data-aos="fade-up">{{ $t('gate.hall_title') }}</span>
      </h2>
      <p data-aos="fade-up">{{ $t('gate.hall_text') }}</p>
    </section>

    <Slider :view_list="this.json.gate.gate_list" />

    <section class="article__text article--bg-color-shade-lighter">
      <h2 class="article__grid-headline">{{ $t('gate.office_title') }}</h2>
      <div class="article__grid">
        <div class="article__grid-column stack">
          <strong>&nbsp;</strong>
          <span v-for="(item, i) in this.json.gate.floor_grid.column_0" :key="`col0-cell${i}`" class="article__grid-cell article__grid-stack">
            <img :data-src="getImagePath(`stack0${i+1}.svg`)" class="article__grid-stack-image">
          </span>
          <strong>&nbsp;</strong>
        </div>
        <div class="article__grid-column numbers">
          <strong>{{ $t(this.json.gate.floor_grid.floor_headers[1]) }}</strong>
          <span v-for="(item, i) in this.json.gate.floor_grid.column_1" :key="`col1-cell${i}`" class="article__grid-cell">{{ item }}</span>
        </div>
        <div class="article__grid-column numbers--mobile">
          <strong>&nbsp;</strong>
          <span v-for="(item, i) in this.json.gate.floor_grid.column_1" :key="`col1-cell${i}`" class="article__grid-cell">{{ item }}</span>
        </div>
        <div class="article__grid-column usage">
          <strong>{{ $t(this.json.gate.floor_grid.floor_headers[2]) }}</strong>
          <span v-for="(item, i) in this.json.gate.floor_grid.column_2" :key="`col12-cell${i}`" class="article__grid-cell">{{ item }}</span>
        </div>
        <div class="article__grid-column area">
          <strong>{{ $t(this.json.gate.floor_grid.floor_headers[3]) }}</strong>
          <span v-for="(item, i) in this.json.gate.floor_grid.column_3" :key="`col3-cell${i}`" class="article__grid-cell">{{ item }}</span>
        </div>
      </div>
    </section>

    <Accordion :floor_list="this.json.gate.floor_list" :active_accordion="2" />

    <section class="article__text article--bg-corporate-lightest equipment">
      <h2 data-aos="fade-up">{{ $t('gate.equipment_title') }}</h2>
      <div data-aos="fade-up" data-aos-anchor=".equipment" data-aos-delay="600" class="text__columns">
        <p v-for="(item, i) in this.json.gate.equipment_list" :key="`eq${i}`">{{ item }}</p>
      </div>
    </section>
  </article>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
import LogoInverted from '@/components/LogoInverted.vue'
import Slider from '@/components/Slider.vue'

export default {
  name: 'GateAndHall',
  components: {
    Accordion,
    LogoInverted,
    Slider,
  },
  metaInfo() {
    return {
      title: this.$t('gate.meta_title'),
      meta: [
        { name: 'description', content:  this.$t('global.meta_description') },
        { name: 'keywords', content:  this.$t('global.meta_keywords') }
      ]
    }
  },
  data () {
    return {
      activeAccordion: 0,
      json: { view_list: "gate.view_list" },
    }
  },
  created () {
    if(this.$i18n.locale === 'en'){
      this.json = require('@/locales/en.json');
    }
    else{
      this.json = require('@/locales/de.json');
    }
  },
  updated () {
    this.$nextTick(() => {
      if(this.$i18n.locale === 'en'){
        this.json = require('@/locales/en.json');
      }
      else{
        this.json = require('@/locales/de.json');
      }
    });
  },
  methods: {
    getImagePath(src) {
      return require(`../assets/images/gate/${src}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.article {
  &__mood-text {
    h3 {
      color: $color-shade-lightest;
    }
  }
}

.article__grid-stack-image {
  @include tablet {
    left: -20px;
  }

  height: 85px;
  width: 165px;
  max-width: none;
  top: -30px;
}

.groundplan {
  @include tablet {
    margin-bottom: 6rem;
  }

  width: 100%;
  margin-bottom: 3rem;
}

</style>
